
import Vue from 'vue'
import Vuex from 'vuex'
import homepage from './modules/homepage'
import loading from './modules/loading'


Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    homepage,
    loading,
  }
});


