<template>
  <v-app class="my-custom-app">

    <!-- Navbar Starrt -->
    <div class="container-xxl">
      <nav class="navbar navbar-expand-lg bg-white navbar-light sticky-top py-lg-0"
        style="margin-top:4px;margin-bottom:4px">
        <a href="index.html" class="navbar-brand d-flex align-items-center d-lg-none " v-if="isSmallScreen">
          <img src="img/logo.svg" alt="" style="width: 200px;">
        </a>
        <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="navbar-nav ms-auto py-3 py-lg-3">
            <a @click="$vuetify.goTo('#top', { duration: 100, easing: 'easeInOutCubic' })"
              class="nav-item nav-link active"><span>HOME</span></a>
            <a @click="$vuetify.goTo('#about', { duration: 100, easing: 'easeInOutCubic' })"
              class="nav-item nav-link"><span>ABOUT US</span></a>
            <a @click="$vuetify.goTo('#services', { duration: 100, easing: 'easeInOutCubic' })"
              class="nav-item nav-link"><span>OUR SERVICES</span></a>
            <a @click="$vuetify.goTo('#loconav', { duration: 100, easing: 'easeInOutCubic' })"
              class="nav-item nav-link"><span>LOCONAV</span></a>
            <a @click="$vuetify.goTo('#software', { duration: 100, easing: 'easeInOutCubic' })"
              class="nav-item nav-link"><span>SOFTWARE PRODUCTS</span></a>
            <a @click="$vuetify.goTo('#products', { duration: 100, easing: 'easeInOutCubic' })"
              class="nav-item nav-link"><span>SHOPPING CART</span></a>
            <a @click="$vuetify.goTo('#courses', { duration: 100, easing: 'easeInOutCubic' })"
              class="nav-item nav-link"><span>COURSES</span></a>
            <a @click="$vuetify.goTo('#careers', { duration: 100, easing: 'easeInOutCubic' })"
              class="nav-item nav-link"><span>CAREERS</span></a>
            <a @click="$vuetify.goTo('#contact', { duration: 100, easing: 'easeInOutCubic' })"
              class="nav-item nav-link border-end-0 pe-0">CONTACT US</a>
          </div>
        </div>
      </nav>
    </div>

    <!-- Navbar End -->

    <!-- Carousel Start -->
    <div class="container-fluid p-0 mb-4 wow fadeIn" data-wow-delay="0.1s">
      <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#header-carousel" data-bs-slide-to="0" class="active" aria-current="true"
            aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#header-carousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#header-carousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="w-100" src="img/banner-1.jpg" alt="Image" />
          </div>
          <div class="carousel-item">
            <img class="w-100" src="img/banner-1.jpg" alt="Image" />
          </div>
          <div class="carousel-item">
            <img class="w-100" src="img/banner-1.jpg" alt="Image" />
          </div>
        </div>
      </div>
    </div>
    <!-- Carousel End -->
    <div class="nav-2 container-xxl py-3">
      <ul>
        <li>
          <a @click="$vuetify.goTo('#top', { duration: 100, easing: 'easeInOutCubic' })">
            <img src="img/svg-icons/nav-icon-1.svg" alt="">
            <span class="white--text">HOME</span>
          </a>
        </li>
        <li>
          <a @click="$vuetify.goTo('#about', { duration: 100, easing: 'easeInOutCubic' })">
            <img src="img/svg-icons/nav-icon-2.svg" alt="">
            <span class="white--text">ABOUT US</span>
          </a>
        </li>
        <li>
          <a @click="$vuetify.goTo('#services', { duration: 100, easing: 'easeInOutCubic' })">
            <img src="img/svg-icons/nav-icon-3.svg" alt="">
            <span class="white--text">OUR SERVICES</span>
          </a>
        </li>
        <li>
          <a @click="$vuetify.goTo('#loconav', { duration: 100, easing: 'easeInOutCubic' })">
            <img src="img/svg-icons/nav-icon-4.svg" alt="">
            <span class="white--text">LOCONAV</span>
          </a>
        </li>
        <li>
          <a @click="$vuetify.goTo('#software', { duration: 100, easing: 'easeInOutCubic' })">
            <img src="img/svg-icons/nav-icon-5.svg" alt="">
            <span class="white--text">SOFTWARE PRODUCTS</span>
          </a>
        </li>
        <li>
          <a @click="$vuetify.goTo('#products', { duration: 100, easing: 'easeInOutCubic' })">
            <img src="img/svg-icons/nav-icon-6.svg" alt="">
            <span class="white--text">SHOPPING CART</span>
          </a>
        </li>
        <li>
          <a @click="$vuetify.goTo('#courses', { duration: 100, easing: 'easeInOutCubic' })">
            <img src="img/svg-icons/nav-icon-7.svg" alt="">
            <span class="white--text">COURSES</span>
          </a>
        </li>
        <li>
          <a @click="$vuetify.goTo('#careers', { duration: 100, easing: 'easeInOutCubic' })">
            <img src="img/svg-icons/nav-icon-8.svg" alt="">
            <span class="white--text">CAREERS</span>
          </a>
        </li>
        <li>
          <a @click="$vuetify.goTo('#contact', { duration: 100, easing: 'easeInOutCubic' })">
            <img src="img/svg-icons/nav-icon-9.svg" alt="">
            <span class="white--text">CONTACT US</span>
          </a>
        </li>
      </ul>
    </div>
    <!-- About Start -->
    <div class="container-xxl py-2 mt-2">
      <div class="row g-3">
        <div class="col-lg-8 wow fadeInUp" data-wow-delay="0.1s">
          <img class="w-100" src="img/about-banner.png" alt="" style="object-fit: cover" />
        </div>
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s" id="about">
          <div class="h-100">
            <div class="">
              <h2 class="heading-about">ABOUT US</h2>
              <p class="title-2 mt-2">We Caters For All Your<br><span class="ft-700">Personal & Professional</span> Needs!
              </p>
            </div>
            <p>
              Pascal Computer Services leverages its 30 years of experience in the Mauritian IT industry to provide our
              clients with professional solutions and high-end products combined with the highest quality of service.</p>
            <p class="mb-4">
              As a market leading company, we have long standing and close relationships with clients in both the public
              and private sectors. This gives us a unique industry insight that allows us provide tailor made commercial
              products and services that meet the needs of our clients and thereby helps them achieve their commercial
              goals.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- About End -->
    <!-- Carouser 2 -->
    <div class="container-xxl py-2 mb-3 wow fadeIn" data-wow-delay="0.1s">
      <div class="row">
        <div class="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
          <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel-2">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img class="w-100" src="img/banner-sec-2.jpg" alt="Image" />
              </div>
              <div class="carousel-item">
                <img class="w-100" src="img/banner-sec-2.jpg" alt="Image" />
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleSlidesOnly"
              data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleSlidesOnly"
              data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>

    </div>
    <!-- Carouser 2 End -->
    <!--  -->
    <div class="container-xxl py-3">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button ft-800" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
              aria-expanded="true" aria-controls="collapseOne">
              WHY PASCAL?
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              Between 1968 and 1969, Niklaus Wirth designed an imperative and technical programming language called
              PASCAL. This programming language was named in honor of the French mathematician, philosopher and physicist
              Blaise Pascal. It is a small and efficient language envisioned to encourage good programming practices using
              structured programming and data structuring. In essence, the PASCAL programming language set the standard
              for quality. This has contributed to the identity of Pascal Computer Services Ltd; we are committed to
              providing the highest quality of service and professional IT solutions to local and overseas markets. We
              specialize in building different types of software with a focus on management and business-oriented
              solutions.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed ft-800" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              OUR TEAM
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              Our staff comprises a dynamic team of highly qualified, dedicated and experienced technical and
              administrative professionals. PCS is able to offer a wide range of support and client service through the
              support and dedication of our technical and management support staff. The dynamic environment of PCS has
              allowed our professionals to achieve their full potential and grow in their level of expertise.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button class="accordion-button collapsed ft-800" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              OUR CAPABILITIES & ACTIVITIES
            </button>
          </h2>
          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              We devote ourselves to providing an excellent and unparalleled quality service and high-tech support to our
              clients. As a market leading company, we constantly update our products and services to match the latest
              technologies and trends within the IT industry. We also provide tailor-made IT solutions to meet the
              requirements of our clients at competitive rates.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFour">
            <button class="accordion-button collapsed ft-800" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              OUR APPROACH
            </button>
          </h2>
          <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="collapseFour"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              Our approach is to conceptualize and implement new capabilities that allow clients to take their business to
              the next level. Our aim is to always bring a variety of unique products and solutions that will help our
              clients in developing high-performance businesses. We have a range of suppliers which allow us to provide a
              number of solutions tailored to the needs of our clients. We are committed to building strong partnerships
              so that clients get maximum value on their investments.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingfive">
            <button class="accordion-button collapsed ft-800" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
              OUR BELIEF
            </button>
          </h2>
          <div id="collapsefive" class="accordion-collapse collapse" aria-labelledby="collapsefive"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              At Pascal Computer Services Ltd, we believe in empowering our employees and teamwork. We work together
              towards a common shared vision where everyone is given the chance to put into practice their knowledge to
              provide quality products and services to our clients. Our company motto is, “if clients are not satisfied,
              then we are not happy”. We believe our clients to be our strength and our biggest asset. We work with this
              motto daily to ensure that we provide a consistent and high level of service.
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <!--  -->
    <div class="container-xxl py-3">
      <div class="row g-2">
        <h2 class="heading-1" id="services">OUR SERVICES</h2>
        <div class="col-md-5 f-width">
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #338e61;">PC ASSEMBLING SYSTEM INTEGRATION</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #09476e;">SUPPLY OF HARDWARE & ACCESSORIES</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #b12222;">BRANDED PC, NOTEBOOK, NETBOOK & PRINTER</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #b9b06d;">SYSTEM DESIGN & STRUCTURED DATA CABLING</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #b12222;">NETWORKING & WIRELESS SOLUTIONS</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #b9b06d;">THIRD PARTY MAINTENANCE</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #494a4b;">WEB DEVELOPMENT & SOLUTIONS</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #338e61;">E-MARKETING SERVICES</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #494a4b;">IT TRAINING & COURSES</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #338e61;">SOFTWARE DEVELOPMENT & CONSULTANCY</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #09476e;">IT ENGINEERING SERVICES</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #b12222;">WEB & VIDEO CONFERENCING</a>
            </div>
          </div>
        </div>
        <div class="col-md-7 f-width">
          <div id="carousel002" class="carousel slide" data-bs-ride="carousel-2">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img class="w-100" src="img/our-services.jpg" alt="Image" />
                <div class="carousel-caption">
                  <div class="container h-100">
                    <div class="row justify-content-center">
                      <div class="col-12 col-lg-10">
                        <h4 class="text-light ft-900 text-center text-uppercase mb-3 animated slideInDown">
                          SYSTEM DESIGN & STRUCTURED DATA CABLING
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <img class="w-100" src="img/our-services.jpg" alt="Image" />
                <div class="carousel-caption">
                  <div class="container h-100">
                    <div class="row justify-content-center">
                      <div class="col-12 col-lg-10">
                        <h4 class="text-light ft-900 text-center text-uppercase mb-3 animated slideInDown">
                          SYSTEM DESIGN & STRUCTURED DATA CABLING
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carousel002" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carousel002" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <!--  -->
    <div style="background-color: #E4E5E6;" id="loconav">
      <div class="container-xxl py-3">
        <div class="row g-3">
          <h2 class="heading-1 txt-color-1 mt-5">LOCONAV</h2>
          <div class="col-md-7 mt-0 mb-3">
            <div id="carousel003" class="carousel slide" data-bs-ride="carousel-2">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img class="w-100" src="img/loconov-banner.jpg" alt="Image" />
                </div>
                <div class="carousel-item">
                  <img class="w-100" src="img/loconov-banner.jpg" alt="Image" />
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carousel003" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carousel003" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div class="col-md-5 mt-0">
            <h2><img src="img/locoNavLogo.svg" alt="" class="locoNov mb-3"></h2>
            <p>Pascal computer services has recently launched a telematics division, in partnership with Loconav, the
              worlds fastest growing Fleet Tech Company, with a presence in over 75 countries, covering over 5 million
              vehicles globally.</p>

            <p>Through Loconavs groundbreaking advanced Al enabled software, pascal computers aims to democratise access
              to fleet management solutions, and enable Mauritius to increase fleet efficiency, safety and manage
              operating costs.</p>

            <p>Through their partnership, pascal computer services and Loconav aim to catapult businesses to great heights
              while keeping an eye on their vehicles, costs, employees, and fuel, all on a single platform.</p>

            <p>Our value proposition is simple, we enable you to focus on your core strengths, while we maximise your
              efficiency.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <!--  -->
    <div class="container-xxl py-3">
      <div class="row g-2">
        <h2 class="heading-1 mt-5" id="software">OUR IT SOFTWARE SOLUTIONS</h2>
        <p>We are specialists in building software with a focus on management and commercial solutions. Combined with our
          consulting services, we can ensure that we provide solutions that go beyond the current industry standards. Our
          software solutions include:</p>
        <div class="col-md-7 f-width">
          <div id="carouselbc" class="carousel slide" data-bs-ride="carousel-2">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img class="w-100" src="img/ss-banner.png" alt="Image" />
                <div class="carousel-caption">
                  <div class="container h-100">
                    <div class="row justify-content-center">
                      <div class="col-12 col-lg-10">
                        <h4 class="text-light ft-900 text-right text-uppercase mt-4 animated slideInDown">
                          BUSINESS IT CONSULTING
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <img class="w-100" src="img/ss-banner.png" alt="Image" />
                <div class="carousel-caption">
                  <div class="container h-100">
                    <div class="row justify-content-center">
                      <div class="col-12 col-lg-10">
                        <h4 class="text-light ft-900 text-right text-uppercase mt-4 animated slideInDown">
                          BUSINESS IT CONSULTING
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselbc" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselbc" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="col-md-5 f-width">
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #338e61;">BUSINESS IT CONSULTING</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #09476e;">STRATEGY CONSULTING</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #b12222;">BUSINESS ANALYTICS</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #b9b06d;">INFORMATION SECURITY CONSULTING</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #b12222;">WEB PORTAL DEVELOPMENT</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #b9b06d;">HEALTHCARE IT SOLUTIONS</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #494a4b;">MOBILE – WIRELESS APPLICATIONS</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #338e61;">SOLUTIONS FOR INSTITUTIONS</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #494a4b;">NEXT GENERATION EPORTAL SOLUTIONS</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #338e61;">DIGITAL MARKETING</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #09476e;">FORENSIC SOLUTIONS</a>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <a class="ab white--text" style="background-color: #b12222;">ENTERPRISE APPLICATION SERVICES</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <!--  -->
    <div style="background-color: #E4E5E6;" id="courses">
      <div class="container-xxl py-5">
        <div class="row g-5">
          <div class="col-md-5">
            <h2 class="heading-1 txt-color-2">SUPPORT & MAINTENANCE</h2>
            <p>We focus on building a long-term relationship with our clients centered around client satisfactions. Our
              highly experienced technical and support team use their expertise to provide our clients with reliable and
              efficient support and maintenance services at all times. </p>
          </div>
          <div class="col-md-7 px-5">
            <h2 class="heading-1 txt-color-1">AS A HARDWARE VENDOR</h2>
            <p>We carry our own branded computer, ‘Future Computer System’ to meet the customised requirements of our
              customers. We also provide IT hardware products (Branded PC & Laptops, Servers, switch, Routers, Printers
              and UPS)
              We source the highest quality components so as to provide the innovative products at the best value to our
              esteemed customers.</p>
          </div>
          <div class="col-md-12 mt-2">
            <h2 class="heading-1 txt-color-3">OUR CLIENTS ARE OUR ASSET</h2>
            <p>Pascal Computer Services Ltd caters for all the IT needs of the private sector, the public sector as well
              as of individuals. With our quality products and services, we have not only been able to build a very large
              database of clientele but have also won their loyalty and retained their custom since many years. Our list
              of clients is a testimony to this fact. Some of our clients are:</p>
          </div>
        </div>
        <div class="row g-4 mt-1">
          <div class="col-md-2 col-6">
            <a class="yr-ass-box bg-c-2 white--text">
              <img src="img/svg-icons/Client-Asset-1.svg" alt="">
              <span>GOVERNMENTAL BODIES / MINISTRIES</span>
            </a>
          </div>
          <div class="col-md-2 col-6 white--text">
            <a class="yr-ass-box bg-c-1">
              <img src="img/svg-icons/Client-Asset-2.svg" alt="">
              <span class="white--text">EDUCATIONAL &
                TRAINING INSTITUTIONS</span>
            </a>
          </div>
          <div class="col-md-2 col-6">
            <a class="yr-ass-box bg-c-2 white--text">
              <img src="img/svg-icons/Client-Asset-3.svg" alt="">
              <span class="white--text">FINANCIAL<br>
                INSTITUTIONS</span>
            </a>
          </div>
          <div class="col-md-2 col-6 white--text">
            <a class="yr-ass-box bg-c-1">
              <img src="img/svg-icons/Client-Asset-4.svg" alt="">
              <span class="white--text">BUSINESS PROCESS
                OUTSOURCING (BPO)</span>
            </a>
          </div>
          <div class="col-md-2 col-6 white--text">
            <a class="yr-ass-box bg-c-2">
              <img src="img/svg-icons/Client-Asset-5.svg" alt="">
              <span class="white--text">INSURANCE<br>
                COMPANIES</span>
            </a>
          </div>
          <div class="col-md-2 col-6 white--text">
            <a class="yr-ass-box bg-c-1">
              <img src="img/svg-icons/Client-Asset-6.svg" alt="">
              <span class="white--text">INDIVIDUAL CLIENTS
                AND MANY MORE….</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--  -->

    <!--  -->
    <div class="container-xxl py-5">
      <div class="row g-3">
        <h2 class="heading-1 txt-color-1">TECHNICAL TEAM</h2>
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
          <div class="h-100">

            <p>Our technical staff comprises of a dynamic team of highly qualified and experienced persons whose aim is to
              provide customer satisfaction at all levels.</p>

            <p>We believe they are the most innovative and talented technical staff working anywhere in this industry.</p>

            <p>This expertise is evident in our technology and our systems, which exceeds all critical industry
              specifications for precision, availability and reliability.</p>
          </div>
        </div>
        <div class="col-lg-8 wow fadeInUp" data-wow-delay="0.1s">
          <img class="w-100 border-radius" src="img/tech-team.jpg" alt="" style="object-fit: cover" />
        </div>
      </div>
    </div>
    <!--  -->

    <!--  -->
    <div class="container-xxl carousel-products py-3" id="products">
      <div class="row g-3">
        <div class="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
          <div class="h-100">
            <h2 class="heading-1 mb-4">LATEST PRODUCTS</h2>
            <div class="col-md-12">
              <div class="row mx-auto my-auto justify-content-center">
                <div id="recipeCarousel" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-inner" role="listbox">
                    <div v-for="(set, i) in products" :key="i" :class="i == 0 ? 'carousel-item active' : 'carousel-item'">
                      <div class="row g-3">
                        <div class="col-md-3" v-for="(item, index) in set" :key="index">
                          <div class="card">
                            <div class="card-img">
                              <img :src="item.img" class="img-fluid" />
                            </div>
                            <div class="card-caption bg-c-1">
                              <p class="mb-2">{{ item.title }}</p>
                              <h5>{{ "Rs " + item.price }}</h5>
                              <div class="row mt-3">
                                <div class="col-6">
                                  <div class="pro-box-btn">
                                    <span class="bg-c-2"><i class="fa fa-info"></i></span>
                                    More Info
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="pro-box-btn">
                                    <span class="bg-c-2"><i class="fa fa-shopping-cart"></i></span>
                                    Add to Cart
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a class="carousel-control-prev bg-transparent w-aut" href="#recipeCarousel" role="button" @click="pervProducts()"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  </a>
                  <a class="carousel-control-next bg-transparent w-aut" href="#recipeCarousel" role="button" @click="nextProducts()"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <!-- Carouser 2 -->
    <div class="container-xxl py-5 wow fadeIn" data-wow-delay="0.1s" id="careers">
      <h2 class="heading-1 txt-color-1 mb-3">CAREERS</h2>
      <div id="carouselCareers" class="carousel slide" data-bs-ride="carousel-2">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="w-100" src="img/career-banner-1.jpg" alt="Image" />
          </div>
          <div class="carousel-item">
            <img class="w-100" src="img/career-banner-1.jpg" alt="Image" />
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselCareers" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselCareers" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <!-- Carouser 2 End -->

    <!-- Appointment Start -->
    <div class="container-xxl my-3 py-0 wow fadeIn" data-wow-delay="0.1s" id="contact">
      <h2 class="heading-1 txt-color-2">CONTACT US</h2>
      <div class="col-md-12 mb-3">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14980.659436753966!2d57.5036471!3d-20.1688602!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xded40b62f4c044a2!2sPascal%20Computer%20Services!5e0!3m2!1sen!2sin!4v1674237362140!5m2!1sen!2sin"
          width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div class="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
        <form class="contact-form" @submit.prevent="handleSubmit">
          <div class="row g-3">
            <div class="col-sm-6">
              <div class="form-floating">
                <input type="text" class="form-control border-0" id="gname" v-model="formFields.name" required
                  placeholder="Gurdian Name" />
                <label for="gname">Your Name</label>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-floating">
                <input type="email" class="form-control border-0" id="gmail" required v-model="formFields.email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" placeholder="Gurdian Email" />
                <label for="gmail">Your Email</label>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-floating">
                <input type="text" class="form-control border-0" id="cname" v-model="formFields.mobile"
                  placeholder="Child Name" pattern="^\+(?:[0-9]●?){6,14}[0-9]$"
                  title="Please enter a valid phone number with country code (e.g. +1234567890)" required />
                <label for="cname">Your Mobile</label>
              </div>
              <div class="invalid-feedback">
                Please enter a valid phone number with country code (e.g. +1234567890).
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-floating">
                <input type="text" class="form-control border-0" id="cage" v-model="formFields.service" required
                  placeholder="Child Age" />
                <label for="cage">Service Type</label>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating">
                <textarea class="form-control border-0" placeholder="Leave a message here" id="message"
                  v-model="formFields.message" required style="height: 100px"></textarea>
                <label for="message">Message</label>
              </div>
            </div>
            <div class="col-12 text-end">
              <button class="btn btn-primary btn-send mt-4" type="submit">
                SEND
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    </div>
    <!-- Appointment End -->

    <!-- Footer Start -->
    <div class="container-xxl footer mt-5 wow fadeIn" data-wow-delay="0.1s">
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <p>
                Pascal Computer Services<br>
                40/42, St. Georges Street,<br>
                Port Louis, Mauritius<br>
                +230 213 1518<br>
                +230 213 1519<br>
                marketing@pascalcomputer.net<br>
                showroom@pascalcomputer.net
              </p>
            </div>
            <div class="col-lg-6 col-md-12">
              <p>
                Pascal Computer Services Ltd an ISO 9001:2008 certified company has been in the IT industry since 1990.
                Our corporate mission is to provide high-end IT products, professional IT solutions and quality services
                to local markets as well as our overseas customers.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 text-end py-4">
          <img src="img/logo.svg" alt="" class="ftr-logo">
        </div>
      </div>
    </div>
    <div class="container-fluid copyright mt-4 bg-c-1">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center py-3 text-white">
            Copyright 2022 Pascal Computer - Designed by J&Z Artstanding International
          </div>
        </div>
      </div>
    </div>
    <!-- Footer End -->
    <!-- Back to Top -->
    <a @click="$vuetify.goTo('#systemBar', { duration: 100, easing: 'easeInOutCubic' })"
      class="btn btn-lg btn-primary btn-lg-square back-to-top"><i class="bi bi-arrow-up"></i></a>
    <v-overlay :value="loading">
      <!-- <v-progress-circular indeterminate size="64"></v-progress-circular> -->
      <div class="spinner-grow text-primary" role="status"></div>
    </v-overlay>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      formFields: {
        name: "",
        email: "",
        mobile: "",
        service: "",
        message: "",
      },
    };
  },
  created() {
    this.getProducts();
  },
  methods: {
    ...mapActions(["getProducts", "sendForm", "pervProducts","nextProducts"]),
    async handleSubmit() {
      console.log(this.formFields);
      this.sendForm(this.formFields);
      this.formFields.name = "";
      this.formFields.email = "";
      this.formFields.mobile = "";
      this.formFields.service = "";
      this.formFields.message = "";
    },
  },
  computed: {
    ...mapGetters(["products", "loading", "nextPage"]),

    isSmallScreen() {
      return this.$vuetify.breakpoint.width <= 990
    }
  },
};
</script>
<style>.my-custom-app {
  background-color: transparent !important;
  font-family: inherit !important;
}</style>