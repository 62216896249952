import axios from "axios";

const state = {
  expansionList: [
    {
      title: "WHY PASCAL?",
      show: false,
      description:
        "Between 1968 and 1969, Niklaus Wirth designed an imperative and technical programming language called PASCAL. This programming language was named in honor of the French mathematician, philosopher and physicist Blaise Pascal. It is a small and efficient language envisioned to encourage good programming practices using structured programming and data structuring. In essence, the PASCAL programming language set the standard for quality. This has contributed to the identity of Pascal Computer Services Ltd; we are committed to providing the highest quality of service and professional IT solutions to local and overseas markets. We specialize in building different types of software with a focus on management and business-oriented solutions.",
    },
    {
      title: "OUR TEAM",
      show: false,
      description:
        "This is the second item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
      title: "OUR CAPABILITIES & ACTIVITIES",
      show: false,
      description:
        "This is the third item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
      title: "OUR APPROACH",
      show: false,
      description:
        "This is the third item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
      title: "OUR BELIEF",
      show: false,
      description:
        "This is the third item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
  ],
  services: [
    {
      color: "#338e61",
      text: "PC ASSEMBLING SYSTEM INTERGRATION",
    },
    {
      color: "#09476e",
      text: "SUPPLY OF HARDWARE & ACCESSORIES",
    },
    {
      color: "#b12222",
      text: "BRANDED PC, NOTEBOOK, NETBOOK & PRINTER",
    },
    {
      color: "#b9b06d",
      text: "SYSTEM DESIGN & STRUCTURED DATA CABLING",
    },
    {
      color: "#b12222",
      text: "NETWORKING & WIRELESS SOLUTIONS",
    },
    {
      color: "#b9b06d",
      text: "THIRD PARTY MAINTENANCE",
    },
    {
      color: "#494a4b",
      text: "WEB DEVELOPMENT & SOLUTIONS",
    },
    {
      color: "#338e61",
      text: "E-MARKETING SERVICES",
    },
    {
      color: "#494a4b",
      text: "IT TRAINING & COURSES",
    },
    {
      color: "#338e61",
      text: "SOFTWARE DEVELOPMENT & CONSULTANCY",
    },
    {
      color: "#09476e",
      text: "IT ENGINEERING SERVICES",
    },
    {
      color: "#b12222",
      text: "WEB & VIDEO CONFERENCING",
    },
  ],
  softwareServices: [
    {
      color: "#338e61",
      text: "BUSINESS IT CONSULTING",
    },
    {
      color: "#09476e",
      text: "STRATEGY CONSULTING",
    },
    {
      color: "#b12222",
      text: "BUSINESS ANALYTICS",
    },
    {
      color: "#b9b06d",
      text: "INFORMATION SECURITY CONSULTING",
    },
    {
      color: "#b12222",
      text: "WEB PORTAL DEVELOPMENT",
    },
    {
      color: "#b9b06d",
      text: "HEALTHCARE IT SOLUTIONS",
    },
    {
      color: "#494a4b",
      text: "MOBILE – WIRELESS APPLICATIONS",
    },
    {
      color: "#338e61",
      text: "SOLUTIONS FOR INSTITUTIONS",
    },
    {
      color: "#494a4b",
      text: "NEXT GENERATION EPORTAL SOLUTIONS",
    },
    {
      color: "#338e61",
      text: "DIGITAL MARKETING",
    },
    {
      color: "#09476e",
      text: "FORENSIC SOLUTIONS",
    },
    {
      color: "#b12222",
      text: "ENTERPRISE APPLICATION SERVICES",
    },
  ],
  pages: [
    {
      title: "HOME",
      link: "/",
      icon: "nav-icon-1.svg",
    },
    {
      title: "ABOUT US",
      link: "",
      icon: "nav-icon-2.svg",
    },
    {
      title: "OUR SERVICES",
      link: "",
      icon: "nav-icon-3.svg",
    },
    {
      title: "LOCNAV",
      link: "",
      icon: "nav-icon-4.svg",
    },
    {
      title: "SOFTWARE PRODUCTS",
      link: "",
      icon: "nav-icon-5.svg",
    },
    {
      title: "SHOPPING CART",
      link: "",
      icon: "nav-icon-6.svg",
    },
    {
      title: "COURSES",
      link: "",
      icon: "nav-icon-7.svg",
    },
    {
      title: "CAREERS",
      link: "",
      icon: "nav-icon-8.svg",
    },
    {
      title: "CONTACT US",
      link: "",
      icon: "nav-icon-9.svg",
    },
  ],
  support: [
    {
      title: "GOVERNMENTAL BODIES / MINISTRIES",
      link: "/",
      icon: "Client-Asset-1.svg",
    },
    {
      title: "EDUCATIONAL &  TRAINING INSTITUTIONS",
      link: "",
      icon: "Client-Asset-2.svg",
    },
    {
      title: "FINANCIAL \n INSTITUTIONS",
      link: "",
      icon: "Client-Asset-3.svg",
    },
    {
      title: "BUSINESS PROCESS OUTSOURCING (BPO)",
      link: "",
      icon: "Client-Asset-4.svg",
    },
    {
      title: "INSURANCE \n COMPANIES",
      link: "",
      icon: "Client-Asset-5.svg",
    },
    {
      title: "INDIVIDUAL CLIENTS AND MANY MORE….",
      link: "",
      icon: "Client-Asset-6.svg",
    },
  ],
  products: {},
};
const getters = {
  expansionList(state) {
    return state.expansionList;
  },
  services(state) {
    return state.services;
  },
  pages(state) {
    return state.pages;
  },
  softwareServices(state) {
    return state.softwareServices;
  },
  support(state) {
    return state.support;
  },
  products(state) {
    if (!(state.products && state.products.data)) {
      // Handle the case where state.products or state.products.data is undefined
      return [];
    }
    const stateProducts = state.products.data;
    const productsWithProperties = stateProducts.map((product, index) => {
      return {
        ...product,
        iconColor: index % 2 === 0 ? "#09476e" : "#338e61",
        card: index % 2 === 0 ? "#338e61" : "#09476e"
      };
    });
  
    const productsInSetsOfFour = [];
    for (let i = 0; i < productsWithProperties.length; i += 4) {
      productsInSetsOfFour.push(productsWithProperties.slice(i, i + 4));
    }
  
    return productsInSetsOfFour;
  },
  nextPage(state) {
    return state.products.next_page_url;
  },
  prevPage(state) {
    return state.products.prev_page_url;
  },
};
const actions = {
  async getProducts({ commit }) {
    commit("loading", true);
    const response = await axios
      .get(process.env.VUE_APP_API + "pcsWebProducts")
      .then((response) => {
        commit("setProducts", response.data.products);
      })
      .catch((error) => {
        if ("message" in error.response.data) {
          console.log(error.response.data.message);
        } else {
          console.log("Couldnt get products");
        }
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  async nextProducts({ commit, getters }) {
    if (
      getters.nextPage != null &&
      getters.nextPage != ""
    ) {
      commit("loading", true);

      await axios
        .get(getters.nextPage)
        .then((response) => {
          commit("setProducts", response.data.products);
        })
       .catch((error) => {
         console.log(error);
        })
        .finally(() => {
        commit("loading", false);
    });
    }
  },
  async pervProducts({ commit, getters }) {
    if (
      getters.prevPage != null &&
      getters.prevPage != ""
    ) {
      commit("loading", true);

      await axios
        .get(getters.prevPage)
        .then((response) => {
          commit("setProducts", response.data.products);
        })
       .catch((error) => {
         console.log(error);
        })
        .finally(() => {
        commit("loading", false);
    });
    }
  },
  async sendForm({ commit }, contents) {
    commit("loading", true);
    const response = await axios
      .post(process.env.VUE_APP_API + "sendEmail", contents)
      .then((response) => {
        alert(response.data.message);
      })
      .catch((error) => {
        if ("message" in error.response.data) {
          console.log(error.response.data.message);
          alert("Form not submited");
        } else {
          alert("Form not submited");
        }
      })
      .finally(() => {
        commit("loading", false);
      });
  },
};
const mutations = {
  setProducts(state, products) {
    state.products = products;
  },
  // setNewProducts(state, response) {
  //   // update the array of data
  //   for (let i = 0; i < response.data.length; i++)
  //     state.products.data.push(response.data[i]);
  //   // update the meta data
  //   state.products.current_page = response.current_page;
  //   state.products.first_page_url = response.first_page_url;
  //   state.products.from = response.from;
  //   state.products.last_page = response.last_page;
  //   state.products.last_page_url = response.last_page_url;
  //   state.products.next_page_url = response.next_page_url;
  //   state.products.path = response.path;
  //   state.products.per_page = response.per_page;
  //   state.products.prev_page_url = response.prev_page_url;
  //   state.products.to = response.to;
  //   state.products.total = response.total;
  // },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
